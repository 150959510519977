var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto mb-8",
      staticStyle: { "margin-top": "80px" },
      attrs: { "max-width": "374" },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "text-h4 font-weight-medium mx-auto my-2",
          staticStyle: { width: "40%" },
        },
        [_vm._v(" Author ")]
      ),
      _c(
        "div",
        { staticClass: "pa-4 pt-0" },
        [
          _c("v-img", {
            attrs: {
              src: _vm.authorInfo.Picture
                ? "" + _vm.baseUrl + _vm.authorInfo.Picture.url
                : "",
            },
          }),
        ],
        1
      ),
      _c("v-card-subtitle", { staticClass: "pt-0" }, [
        _c("div", { staticStyle: { "font-size": "17px" } }, [
          _c("span", { staticClass: "font-weight-medium" }, [
            _vm._v(
              _vm._s(
                "" +
                  (_vm.authorInfo.Name || "") +
                  (_vm.authorInfo.Position ? "," : "")
              ) + " "
            ),
          ]),
          _c("i", { staticClass: "text--disabled" }, [
            _vm._v(
              " " +
                _vm._s(
                  "" +
                    (_vm.authorInfo.Position || "") +
                    (_vm.authorInfo.Headline ? "," : "")
                ) +
                "  " +
                _vm._s(_vm.authorInfo.Headline) +
                " "
            ),
          ]),
        ]),
        _c(
          "a",
          {
            staticStyle: { "text-decoration": "none" },
            attrs: {
              href: _vm.authorInfo.WebSite,
              target: "_blank",
              rel: "canonical",
            },
          },
          [_vm._v(" " + _vm._s(_vm.authorInfo.WebSite) + " ")]
        ),
      ]),
      _c("v-card-text", { staticStyle: { "font-size": "15px" } }, [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.prepareDescription(_vm.authorInfo.Description)
            ),
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card
    style="margin-top: 80px;"
    class="mx-auto mb-8"
    max-width="374"
  >
    <v-card-title class="text-h4 font-weight-medium mx-auto my-2" style="width: 40%">
      Author
    </v-card-title>

    <div class="pa-4 pt-0">
      <v-img :src="authorInfo.Picture ? `${baseUrl}${authorInfo.Picture.url}`: ''" />
    </div>

    <v-card-subtitle class="pt-0">
      <div style="font-size: 17px">
        <span class="font-weight-medium">{{ `${authorInfo.Name || ''}${authorInfo.Position ? ',' : ''}` }}&nbsp;</span>
        <i class="text--disabled">
          {{ `${authorInfo.Position || ''}${authorInfo.Headline ? ',' : ''}` }}&nbsp;
          {{ authorInfo.Headline }}
        </i>
      </div>
      <a :href="authorInfo.WebSite"
         target="_blank"
         style="text-decoration: none;"
         rel="canonical"
      >
        {{ authorInfo.WebSite }}
      </a>
    </v-card-subtitle>
    <v-card-text style="font-size: 15px">
      <span v-html="prepareDescription(authorInfo.Description)"></span>
    </v-card-text>
  </v-card>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import httpClient from '@/api/config/strapiHttpClient';
import webConstant from '@/api/config/webConstant';
import showdown from "showdown";

@Component({})
export default class BlogPost extends Vue {
  authorInfo = {};

  baseUrl = webConstant.STRAPI_BASE_URL;

  mounted() {
    httpClient.get(`author-reviewers?filters[Slug][$eq]=${this.$route.params.slug}&populate=Picture`)
      .then((response) => {
        const info = { id: response.data.data[0].id, ...response.data.data[0].attributes };
        if (info.Picture.data === null || info.Picture.data === undefined) {
          info.Picture = {
            url: '',
          };
        } else {
          info.Picture = { id: info.Picture.data.id, ...info.Picture.data.attributes };
        }

        this.authorInfo = info;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareDescription(description) {
    const converter = new showdown.Converter();
    return converter.makeHtml(description);
  }
}
</script>
